<template>
  <v-parallax :src="backgroundImage" style="height: 100%">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" lg="4" md="4" sm="8">
        <v-card class="elevation-5 pa-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <localization/>
          </v-card-actions>
          <v-card-text>
            <div class="layout column align-center">
              <img alt="App Logo" height="120" :src="require('@/../public/img/icons/LOGO.png')" width="120">
              <h1 class="text-center my-4 primary--text">{{ appTitle }}</h1>
            </div>
            <v-form>
              <p style="font-size: 17px; color: #141417;">Gửi thông tin đặt lại mật khẩu tới email </p>
              <v-text-field v-model="user.email" :label="$t('login.email')" append-icon="mdi-email" autocomplete="username" name="email" required
                            type="email"/>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn :to="{name: 'Login'}" color="primary" outlined>
              {{ $t('login.login') }}
            </v-btn>
            <v-spacer/>
            <v-btn :loading="loading" color="primary" @click="onClickSubmit()">
              Xác nhận
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import Localization from '@/admin/widget/AppLocalization.vue';
import config from '@/config'
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Reminder',
  components: {Localization},
  data: () => ({
    appTitle: config.appName,
    loading: false,
    user: {
      email: '',
    },
  }),
  computed: {
    ...mapGetters({
      backgroundImage: 'settings/backgroundImage'
    })
  },
  methods: {
    ...mapActions({
      reminderPass: 'user/reminderPass'
    }),
    async onClickSubmit() {
      let res = await this.reminderPass({ email: this.user.email });
      console.log(res, 'res')
      if (res.data && res.data.error === false) {
        this.noticeSuccess('Check your email!');
      }
    },
  },
};
</script>
<style>
.v-parallax__image-container img {
  bottom: 0;
  left: 0;
  filter: brightness(35%);
  position: fixed;
  right: 0;
  top: 0;
  transform: none !important;
  transition: none;
  width: 100%;
  height: 100%;
}
</style>
